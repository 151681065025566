export function progressBar() {
  const progress_bar = document.querySelector(".js-progress-bar");
  if (progress_bar) {
  document.querySelector(".testimonial ul").addEventListener("scroll", function () {
    // Calculate scroll position
    const scroll_percentage = (this.scrollLeft / (this.scrollWidth - document.documentElement.clientWidth)) * 100;
    const scroll_size = (this.clientWidth / (this.scrollWidth - document.documentElement.clientWidth)) * 20;
    progress_bar.style.backgroundPosition = `${scroll_percentage}% 50%`;
    progress_bar.style.backgroundSize = `${scroll_size}% 100%`;
  });
 }
}
