export function mouseGrabScroll() {
    document.querySelectorAll('.js-mouse-grab-scroll').forEach(scroller => {
        const preventClick = (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
          }
          let isDown = false;
          var isDragged = false;
          let startX;
          let scrollLeft;
    
          scroller.addEventListener("mousedown", e => {
            isDown = true;
            scroller.classList.add("active");
            startX = e.pageX - scroller.offsetLeft;
            scrollLeft = scroller.scrollLeft;
          });
    
          scroller.addEventListener("mouseleave", () => {
            isDown = false;
            scroller.classList.remove("active");
          });
    
          scroller.addEventListener("mouseup", e => {
            isDown = false;
            const elements = scroller.getElementsByTagName("a");
            if(isDragged){
                for(let i = 0; i<elements.length; i++){
                      elements[i].addEventListener("click", preventClick);
                }
            }else{
                for(let i = 0; i<elements.length; i++){
                      elements[i].removeEventListener("click", preventClick);
                }
            }
            scroller.classList.remove("active");
            isDragged = false;
          });
    
          scroller.addEventListener("mousemove", e => {
            if (!isDown) return;
            isDragged =  true;
            e.preventDefault();
            const x = e.pageX - scroller.offsetLeft;
            const walk = x - startX;
            scroller.scrollLeft = scrollLeft - walk;
          });
    });
}

