export function activeLastHover() {
    document.querySelectorAll('.js-active-last-hover').forEach(root => {
        const allItems = root.querySelectorAll('.js-hover-item');
        const allSiblingItems = root.querySelectorAll('.js-hover-item-sibling');
        var startItem = 0;
        if (root.classList.contains("js-center-hover")) {
            startItem = Math.floor(allItems.length / 2)
        }
        allItems[startItem].classList.toggle("active")
        allSiblingItems[startItem]?.classList?.toggle("active")
        allItems.forEach((view, index) => {
            view.addEventListener("mouseenter", e => {
                root.querySelectorAll('.js-hover-item, .js-hover-item-sibling').forEach(v => {
                    v.classList.remove("active");
                })
                e.target.classList.toggle("active");
                allSiblingItems[index]?.classList?.toggle("active");
            });
        })
    });
}