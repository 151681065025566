export function centerScroll() {
    const centerScrollers = document.getElementsByClassName("js-center-scroll")
    for (let i = 0; i < centerScrollers.length; i++) {
        const scroller = centerScrollers[i];
        const items = [];
        for (let j = 0; j < scroller.childNodes.length; j++) {
            const node = scroller.childNodes[j];
            if (node.nodeType == Node.ELEMENT_NODE) {
                items.push(node);
            }
        }
        const centerPosition = Math.floor(items.length / 2)
        const centerChild = items[centerPosition];
        if (centerChild) {
            centerChild.scrollIntoView({ behavior: "auto", inline: "center" });
        }
    }
}

//checks to see if the list items are overflowing. If not, center them.
export function checkForOverFlow() {
    function isOverflown(element) {
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    }

    var els = document.querySelectorAll('.testimonial__list');
    for (var i = 0; i < els.length; i++) {
        var el = els[i];
        if (isOverflown(el)) {
            if (el.classList.contains('justify-center')) {
                el.classList.remove('justify-center');
            }
        } else {
            if (! el.classList.contains('justify-center')) {
                el.classList.add('justify-center');
            }
            
        }
    }
}

window.addEventListener('resize', function(event){
    checkForOverFlow();
});

