export function countUp() {
    document.querySelectorAll('.js-count-up-block').forEach(numberBlock => {
        function scrollCallback() {
            if (isScrolledIntoView(numberBlock)) {
                window.removeEventListener('scroll', scrollCallback);
                animateNumbers(numberBlock);
            }
        }
        window.addEventListener('scroll', scrollCallback);
    });
}

function animateNumbers(root) {
    root.querySelectorAll('.js-count-up').forEach(el => {
      // separate number parts and text parts
        const split = el.textContent.match(/\D+|\d+/gi);
        const number = split[0];
        split.shift();
        const tail = split.join();
        animateValue(el, 0, Number(number), tail, 2000);
    });
}

function animateValue(element, start, end, tail, duration) {
    if (start === end) return;
    const range = end - start;
    let current = start;
    const precision = getPrecision(end);
    const incrementer = getIncrementer(precision);
    const increment = end > start? incrementer : -incrementer;
    const adjustDuration = duration * incrementer;
    const stepTime = Math.abs(Math.floor(adjustDuration / range));

    function animate() {
        current += increment;
        var rounded = Math.round(current * 10) / 10
        element.innerHTML = rounded.toFixed(precision) + tail;
        return rounded === end;
    }

    if (animate()) return;
    const timer = setInterval(function() {
        if (animate()) {
            clearInterval(timer);
        }
    }, stepTime);
}

function getPrecision(number) {
    const precision = (number + "").split(".")[1];
    if (!precision) return 0;
    return precision.length;
}

function getIncrementer(precision) {
    if (!precision) return 1;
    const zeros = Array(precision - 1).fill("0").join("");
    return Number("0." + zeros + "1");
}

function isScrolledIntoView(el) {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    //return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    return elemTop < window.innerHeight && elemBottom >= 0;
}
