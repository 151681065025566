import { createFocusTrap } from 'focus-trap';

export function toggleNavigation() {
    const nav = document.getElementById('site-navigation');
    if (nav == null) return;

    nav.classList.add("trap")
    const focusTrap = createFocusTrap(nav, {
        onActivate: function () {
            nav.classList.add("is-active");
        },
        onDeactivate: function () {
            nav.classList.remove("open", "is-active");
        },
    });

    document.querySelectorAll('.js-toggle-navigation').forEach(toggle => {
        const button = convertLinkToButton(toggle);

        button.addEventListener('click', e => {
            if (nav.classList.contains("open")) {
                focusTrap.deactivate();
            } else {
                nav.classList.add("open");
                focusTrap.activate();
            }
        });
    });
}

function convertLinkToButton(linkItem) {

    let i, len;

    const link = linkItem,
        linkHTML = link.innerHTML,
        linkAtts = link.attributes,
        button = document.createElement('button');

    if (null !== link) {

        // set button content and attributes
        button.innerHTML = linkHTML.trim();
        for (i = 0, len = linkAtts.length; i < len; i++) {
            let attr = linkAtts[i];
            if ('href' !== attr.name) {
                button.setAttribute(attr.name, attr.value);
            }
        }

        link.replaceWith(button);

    }

    return button;

}