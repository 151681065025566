'use strict';

import { centerScroll } from './class-functions/center-scroll';
import { checkForOverFlow } from './class-functions/center-scroll';
import { mouseGrabScroll } from './class-functions/mouse-grab-scroll';
import { accordion } from './class-functions/accordion';
import { clickyMenu } from './class-functions/clicky-menu';
import { activeLastHover } from './class-functions/active-last-hover';
import { toggleNavigation } from './class-functions/toggle-navigation';
import { countUp } from './class-functions/count-up';
import { progressBar } from './class-functions/progress-bar';
import { recognitionBar } from './class-functions/recognition-bar';

document.addEventListener("DOMContentLoaded", function (event) {
    centerScroll();
    checkForOverFlow();
    mouseGrabScroll();
    accordion();
    clickyMenu();
    activeLastHover();
    toggleNavigation();
    countUp();
    progressBar();
    recognitionBar();

    window.scrollTo(0, 0);
});



