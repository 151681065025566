export function accordion() {
    document.querySelectorAll('.js-accordion').forEach(accordion => {
        accordion.addEventListener("click", e => {
            accordion.classList.toggle("active");
            accordion.parentElement.classList.toggle("bg-white");

            const panel = accordion.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight +  200 + "px";
            }
        });
    });
}