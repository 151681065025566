export function recognitionBar() {
    const recognitionBar = document.querySelector(".js-recognition-bar");
    if (recognitionBar){
    document.querySelector(".recognition ul").addEventListener("scroll", function () {
      const scroll_percentage = (this.scrollLeft / (this.scrollWidth - document.documentElement.clientWidth)) * 100;
      const scroll_size = (this.clientWidth / (this.scrollWidth - document.documentElement.clientWidth)) * 20;
      recognitionBar.style.backgroundPosition = `${scroll_percentage}% 50%`;
      recognitionBar.style.backgroundSize = `${scroll_size}% 100%`;
    });
  }
}
